import React, { useState } from "react";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { materialDark } from "react-syntax-highlighter/dist/esm/styles/prism";

const transparentStyle = {
  ...materialDark,
  // Remove the background from the <pre> container
  'pre[class*="language-"]': {
    ...materialDark['pre[class*="language-"]'],
    background: "transparent",
    backgroundColor: "transparent",
  },
  // Remove the background from the <code> block
  'code[class*="language-"]': {
    ...materialDark['code[class*="language-"]'],
    background: "transparent",
    backgroundColor: "transparent",
  },
};

// CodeBlock component
const CodeBlock = ({ children, language = "python" }) => {
  const [copied, setCopied] = useState(false);

  const copyToClipboard = () => {
    navigator.clipboard.writeText(children.trim());
    setCopied(true);
    setTimeout(() => setCopied(false), 2000);
  };

  return (
    <div className="relative bg-gray-900 p-2 rounded mt-2 max-w-full w-full text-sm">
      <button
        onClick={copyToClipboard}
        className="
          absolute top-2 right-2 z-10
          text-xs bg-gray-700 text-white
          px-2 py-1 rounded
          hover:bg-gray-600 transition
        "
      >
        {copied ? "Copied!" : "Copy"}
      </button>

      <SyntaxHighlighter
        language={language}
        style={transparentStyle}
        wrapLongLines={true}
        customStyle={{
          backgroundColor: "transparent",
          padding: 0,
          margin: 0,
        }}
      >
        {children.trim()}
      </SyntaxHighlighter>
    </div>
  );
};

// Documentation component
const Documentation = () => {
  return (
    <div className="text-white px-4 sm:px-6 md:px-8 max-w-4xl mx-auto mt-12">
      <h1 className="text-2xl md:text-3xl font-bold mb-6">API Documentation</h1>

      <p className="mb-4 leading-relaxed break-words">
        Welcome to the API Documentation. Here you will find detailed instructions
        and examples to help you get started with our API.
        <br />
        <br />
        For our full documentation and more examples, please check out our
        documentation{" "}
        <a
          href="https://pypi.org/project/desync-search/"
          target="_blank"
          rel="noreferrer"
          className="text-blue-300 font-bold break-words"
        >
          here
        </a>.
      </p>

      <div className="space-y-4">
        {/* ---- IMPORT SECTION ---- */}
        <div className="bg-gray-800 p-4 rounded-lg shadow-lg">
          <h2 className="text-xl md:text-2xl font-semibold mb-2">Import</h2>
          <p className="text-gray-300 text-sm md:text-base break-words">
            Pip install our desync search tool:
          </p>
          <CodeBlock language="bash">{`pip install desync_search`}</CodeBlock>
        </div>

        {/* ---- CORE SECTION ---- */}
        <div className="bg-gray-800 p-4 rounded-lg shadow-lg">
          <h2 className="text-xl md:text-2xl font-semibold mb-2">Core</h2>
          <p className="text-gray-300 text-sm md:text-base break-words">
            Basic installation:
          </p>
          <CodeBlock language="python">{`import desync_search.core

user_api_key = "YOUR_DESYNC_API_KEY"
client = DesyncClient(user_api_key)`}</CodeBlock>
        </div>

        {/* ---- OUTPUTS SECTION ---- */}
        <div className="bg-gray-800 p-4 rounded-lg shadow-lg">
          <h2 className="text-xl md:text-2xl font-semibold mb-2">Outputs:</h2>
          <p className="text-gray-300 text-sm md:text-base break-words">
            Search a specified URL
            <br />
            Stealth = 10 credits (default choice)
            <br />
            Test = 1 credit
          </p>
          <CodeBlock language="python">{`# Stealth Search (default)
page_data = client.search("https://www.137ventures.com/portfolio")

print("URL:", page_data.url)
print("Text length:", len(page_data.text_content))

# Test Search
test_response = client.search(
    "https://www.python.org",
    search_type="test_search"
)

print("Test search type:", test_response.search_type)`}</CodeBlock>
        </div>

        {/* ---- BASIC SEARCH SECTION ---- */}
        <div className="bg-gray-800 p-4 rounded-lg shadow-lg">
          <h2 className="text-xl md:text-2xl font-semibold mb-2">Basic Search</h2>
          <p className="text-gray-300 text-sm md:text-base break-words">
            Perform a basic search
          </p>
          <CodeBlock language="python">{`print(page_data)
# PageData url=https://www.137ventures.com/portfolio search_type=stealth_search
# timestamp=... complete=True

print(page_data.text_content[:200])  # first 200 chars of text`}</CodeBlock>
          <CodeBlock language="python">{`stealth_response = client.search(
    "https://www.137ventures.com/portfolio",
    scrape_full_html=True,
    remove_link_duplicates=False
)
print(len(stealth_response.html_content), "HTML chars")`}</CodeBlock>
        </div>

        {/* ---- EXAMPLE SECTION ---- */}
        <div className="bg-gray-800 p-4 rounded-lg shadow-lg">
          <h2 className="text-xl md:text-2xl font-semibold mb-2">
            Example: Visit All Internal Links
          </h2>
          <p className="text-gray-300 text-sm md:text-base break-words">
            Example of viewing all internal links. For more examples, please see our
            full library{" "}
            <a
              href="https://pypi.org/project/desync-search/"
              target="_blank"
              rel="noreferrer"
              className="text-blue-500 break-words"
            >
              here
            </a>
            .
          </p>
          <CodeBlock language="python">{`visited = set()

def crawl(client, url):
    if url in visited:
        return
    visited.add(url)

    page_data = client.search(url)  # stealth by default
    print("Scraped:", url, "Found", len(page_data.internal_links), "internal links")

    # For each new internal link, crawl again
    for link in page_data.internal_links:
        if link not in visited:
            crawl(client, link)

# Start from a seed URL
crawl(client, "https://www.137ventures.com/portfolio")`}</CodeBlock>
        </div>
      </div>
    </div>
  );
};

export default Documentation;