import React, { useState, useEffect } from "react";
import { getAPIKeys, generateAPIKey, deactivateAPIKey } from "./HomeService.js"; // Import the specific functions

const APIKeys = () => {
  const [apiKeys, setApiKeys] = useState([]); // State for API keys
  const [error, setError] = useState(null); // State for error handling
  const [userId, setUserId] = useState(null); // State for the logged-in user's ID
  const [email, setEmail] = useState(null);

  useEffect(() => {
    const storedUserId = localStorage.getItem("ourUserId");
    if (storedUserId) {
      setUserId(storedUserId);
    } else {
      setError("User ID not found. Please log in again.");
    }

    const storedUserEmail = localStorage.getItem("email");
    if (storedUserEmail) {
      setEmail(storedUserEmail);
    } else {
      setError("User email not found. Please log in again.");
    }
  }, []);

  useEffect(() => {
    const fetchAPIKeys = async () => {
      if (!userId || !email) return;

      try {
        const result = await getAPIKeys(email);
        if (result.success) {
          setApiKeys(result.data || []);
        } else {
          setError(result.message);
        }
      } catch (error) {
        setError("An error occurred while fetching API keys.");
      }
    };

    fetchAPIKeys();
  }, [userId, email]);

  // Generate a new API key
  const handleGenerateAPIKey = async () => {
    if (!email) {
      setError("User ID not found. Please log in again.");
      return;
    }

    try {
      const result = await generateAPIKey(email);
      if (result.success) {
        setApiKeys((prevKeys) => [[result.data, true, result.data], ...prevKeys]);
      } else {
        setError(result.message);
      }
    } catch (error) {
      setError("An error occurred while generating an API key.");
    }
  };

  // Deactivate API key
  const deactivateKey = async (key, index, starredKey) => {
    try {
      const result = await deactivateAPIKey(key);
      if (result.success) {
        setApiKeys((prevKeys) => {
          const updatedKeys = [...prevKeys];
          updatedKeys[index] = [starredKey, false]; // Update status to Inactive
          return updatedKeys;
        });
      } else {
        setError(result.message);
      }
    } catch (error) {
      setError("An error occurred while deactivating the API key.");
    }
  };

  return (
    <div className="text-white p-4 sm:p-6 max-w-2xl mx-auto mt-4">
      <h1 className="text-2xl sm:text-3xl font-bold mb-6">Manage API Keys</h1>
      {error && <p className="text-red-500 mb-4 text-sm sm:text-base">{error}</p>}

      <button
        onClick={handleGenerateAPIKey}
        className="mb-6 bg-blue-500 hover:bg-blue-600 text-white px-4 py-2 rounded text-sm sm:text-base"
        disabled={!userId}
      >
        Generate New API Key
      </button>

      {/* Table view for larger screens */}
      <div className="hidden sm:block overflow-x-auto shadow-lg rounded-lg">
        <table className="min-w-full bg-gray-800 text-left text-sm sm:text-base">
          <thead>
            <tr className="bg-gray-700">
              <th className="px-4 py-2">API Key</th>
              <th className="px-4 py-2">Status</th>
              <th className="px-4 py-2">Actions</th>
            </tr>
          </thead>
          <tbody>
            {apiKeys.length === 0 ? (
              <tr>
                <td className="px-4 py-2 text-center" colSpan="3">
                  No API keys found.
                </td>
              </tr>
            ) : (
              apiKeys.map(([key, status, fullKey], index) => (
                <tr key={index} className="border-b border-gray-600">
                  <td className="px-4 py-2 break-all">{key}</td>
                  <td className="px-4 py-2">
                    {status ? (
                      <span className="text-green-400">Active</span>
                    ) : (
                      <span className="text-red-400">Inactive</span>
                    )}
                  </td>
                  <td className="px-4 py-2">
                    {status ? (
                      <button
                        onClick={() => deactivateKey(fullKey, index, key)}
                        className="bg-red-500 hover:bg-red-600 text-white px-4 py-2 rounded text-sm sm:text-base"
                      >
                        Deactivate
                      </button>
                    ) : (
                      <span className="text-gray-500 italic">Cannot Reactivate</span>
                    )}
                  </td>
                </tr>
              ))
            )}
          </tbody>
        </table>
      </div>

      {/* Card view for smaller screens */}
      <div className="sm:hidden">
        {apiKeys.length === 0 ? (
          <div className="px-4 py-2 text-sm">No API keys found.</div>
        ) : (
          apiKeys.map(([key, status, fullKey], index) => (
            <div key={index} className="bg-gray-700 rounded-lg shadow p-4 mb-4 text-sm">
              <p className="mb-2">
                <strong>API Key:</strong> {key}
              </p>
              <p className="mb-2">
                <strong>Status:</strong>{" "}
                {status ? (
                  <span className="text-green-400">Active</span>
                ) : (
                  <span className="text-red-400">Inactive</span>
                )}
              </p>
              {status ? (
                <button
                  onClick={() => deactivateKey(fullKey, index, key)}
                  className="bg-red-500 hover:bg-red-600 text-white px-4 py-2 rounded text-sm sm:text-base"
                >
                  Deactivate
                </button>
              ) : (
                <p className="text-gray-500 italic">Cannot Reactivate</p>
              )}
            </div>
          ))
        )}
      </div>
    </div>
  );
};

export default APIKeys;
