import React, { useState } from "react";
import APIKeys from "./APIKeys";
import Documentation from "./Documentation";

const Home = () => {
  const [selectedSection, setSelectedSection] = useState("api-keys");

  const handleNavigation = (section) => {
    if (section === "add-credits") {
      window.location.href = "/add-credits"; // Redirect for Stripe payments
    } else {
      setSelectedSection(section);
    }
  };

  // Determine the alternative section for the footer link
  const getAlternativeSection = () => {
    if (selectedSection === "api-keys") return "documentation";
    if (selectedSection === "documentation") return "api-keys";
    return null;
  };

  const alternativeSection = getAlternativeSection();

  // Map section keys to display names
  const sectionNames = {
    "api-keys": "API Keys",
    documentation: "Documentation",
  };

  return (
    <div className="bg-gradient-to-r from-slate-900 to-slate-700 min-h-screen flex flex-row text-white relative w-full overflow-x-hidden">
      
      {/* Sidebar: hidden on small screens, visible on md+ */}
      <aside className="hidden md:block w-64 bg-gray-800 shadow-lg flex-shrink-0 mt-3">
        <div className="p-4 text-xl font-bold border-b border-gray-700">
          Dashboard
        </div>
        <nav className="flex flex-col space-y-2 p-4">
          <button
            className={`rounded-lg text-left hover:bg-gray-700 px-2 py-2 ${
              selectedSection === "api-keys" ? "bg-gray-700" : ""
            }`}
            onClick={() => handleNavigation("api-keys")}
          >
            API Keys
          </button>
          <button
            className={`p-2 rounded-lg text-left hover:bg-gray-700 ${
              selectedSection === "documentation" ? "bg-gray-700" : ""
            }`}
            onClick={() => handleNavigation("documentation")}
          >
            Documentation
          </button>
          <button
            className="p-2 rounded-lg text-left hover:bg-gray-700"
            onClick={() => handleNavigation("add-credits")}
          >
            Buy Credits
          </button>
        </nav>
      </aside>

      {/* Main Content: min-w-0 prevents flex-grow issues */}
      <main className="flex-1 p-6 mt-8 min-w-0 container mx-auto">
        {selectedSection === "api-keys" && <APIKeys />}
        {selectedSection === "documentation" && <Documentation />}
      </main>

      {/* Footer Link: visible only on small screens */}
      {alternativeSection && (
        <div className="fixed bottom-4 left-1/2 transform -translate-x-1/2 md:hidden">
          <button
            onClick={() => handleNavigation(alternativeSection)}
            className="bg-blue-600 hover:bg-blue-700 text-white px-4 py-2 rounded-full shadow-lg flex items-center space-x-2 transition-colors duration-300"
            aria-label={`Navigate to ${sectionNames[alternativeSection]}`}
          >
            {/* Optional: Add an icon */}
            <span>{`Go to ${sectionNames[alternativeSection]}`}</span>
          </button>
        </div>
      )}
    </div>
  );
};

export default Home;
