// AddCredits.js
import React, { useState, useEffect } from "react";
import { loadStripe } from "@stripe/stripe-js";
import {
  Elements,
  CardElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";

// Replace with your actual publishable key from Stripe
const stripePromise = loadStripe("pk_live_51ObYQDDeoQKG3IgGyOzIXS12G2qijgs3yIzztyV9TW5v1eUVvw0TwpJbH6BRcDx4kLSPDj02slrCZmqgkmoLaiyp00k64eBepQ");

const CARD_ELEMENT_OPTIONS = {
  hidePostalCode: true,
  style: {
    base: {
      fontSize: "1rem",
      color: "#f0f0f0",
      "::placeholder": {
        color: "#b0b0b0",
      },
      fontFamily: "Arial, sans-serif",
      padding: "10px 14px",
    },
    invalid: {
      color: "#ff6b6b",
      iconColor: "#ff6b6b",
    },
  },
};

function CheckoutForm() {
  const stripe = useStripe();
  const elements = useElements();

  const [amount, setAmount] = useState("");
  const [processing, setProcessing] = useState(false);
  const [message, setMessage] = useState("");
  const [email, setEmail] = useState(null);

  useEffect(() => {
    const storedUserEmail = localStorage.getItem("email");
    if (storedUserEmail) {
      setEmail(storedUserEmail);
    }
  }, []);

  // This function notifies your Flask backend that a payment was successful.
  const recordPayment = async (paymentIntent) => {
    const user = {
      email:
        paymentIntent.charges?.data?.[0]?.billing_details?.email || email,
    };

    try {
      const response = await fetch(
        "https://dev-tool-backend-zj7w8.ondigitalocean.app/record-payment",
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            user, // who paid
            amount: paymentIntent.amount, // in cents
            paymentIntentId: paymentIntent.id,
          }),
        }
      );
      const data = await response.json();
      console.log("Payment recorded:", data);
    } catch (error) {
      console.error("Error recording payment:", error);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!stripe || !elements) return;

    setProcessing(true);
    setMessage("");

    // Convert the entered dollar amount to cents.
    const paymentAmount = Math.round(parseFloat(amount) * 100);
    if (isNaN(paymentAmount) || paymentAmount < 100) {
      // Minimum of $1.00 is required.
      setMessage("Please enter a valid amount (minimum $1).");
      setProcessing(false);
      return;
    }

    try {
      // Create a PaymentIntent by calling the Flask backend.
      const response = await fetch(
        "https://dev-tool-backend-zj7w8.ondigitalocean.app/create-payment-intent",
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ amount: paymentAmount }),
        }
      );
      const { clientSecret } = await response.json();

      // Confirm the card payment using the client secret.
      const result = await stripe.confirmCardPayment(clientSecret, {
        payment_method: {
          card: elements.getElement(CardElement),
        },
      });

      if (result.error) {
        setMessage(result.error.message);
      } else if (result.paymentIntent.status === "succeeded") {
        setMessage("Payment succeeded! Credits have been added.");
        // Notify the backend about the successful payment.
        await recordPayment(result.paymentIntent);
      }
    } catch (error) {
      setMessage("Payment failed: " + error.message);
    }
    setProcessing(false);
  };

  return (
    <form
      onSubmit={handleSubmit}
      className="max-w-md w-full mx-auto bg-gray-800 text-gray-100 p-6 rounded-lg border border-gray-700 shadow-lg"
    >
      <h2 className="text-4xl font-semibold text-center mb-6">Add Credits</h2>
      <p className="font-semibold text-center mb-6">$1 = 10,000 credits</p>

      {/* Amount Field */}
      <div className="mb-4">
        <label htmlFor="amount" className="block mb-2 text-sm font-medium">
          Amount ($):
        </label>
        <input
          id="amount"
          type="number"
          value={amount}
          onChange={(e) => setAmount(e.target.value)}
          min="1"
          step="1"
          placeholder="Enter amount in USD"
          className="block w-full p-2.5 rounded border border-gray-600 bg-gray-700 text-gray-100 
                     placeholder-gray-400 focus:outline-none focus:ring-1 
                     focus:ring-blue-500 focus:border-blue-500"
        />
      </div>

      {/* Card Element */}
      <div className="p-4 mb-4 bg-gray-700 rounded">
        <CardElement options={CARD_ELEMENT_OPTIONS} />
      </div>

      {/* Submit Button */}
      <button
        type="submit"
        disabled={!stripe || processing}
        className={`w-full py-2 px-4 rounded text-white font-semibold transition-colors 
          ${
            processing
              ? "bg-gray-600 cursor-not-allowed"
              : "bg-blue-600 hover:bg-blue-500"
          }`}
      >
        {processing ? "Processing..." : "Add Credits"}
      </button>

      {/* Message (Success or Error) */}
      {message && (
        <div
          className={`mt-4 text-center ${
            message.includes("succeeded")
              ? "text-green-400"
              : "text-red-400"
          }`}
        >
          {message}
        </div>
      )}
    </form>
  );
}

export default function AddCredits() {
  return (
    <div className="bg-gradient-to-r from-slate-900 to-slate-700 min-h-screen flex items-center justify-center">
      <Elements stripe={stripePromise}>
        <CheckoutForm />
      </Elements>
    </div>
  );
}
