import React, { useState, useEffect } from "react";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { logoutUser, isAuthenticated } from "../Auth/AuthService";
import { getCredits } from "./NavbarService"; // Import the credits fetching function

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [loggedIn, setLoggedIn] = useState(false);
  const [credits, setCredits] = useState(null); // State to store user credits
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  useEffect(() => {
    const checkAuth = async () => {
      const response = await isAuthenticated();
      setLoggedIn(response.success);

      if (response.success) {
        // Fetch credits for the authenticated user
        const email = localStorage.getItem("email");
        if (email) {
          const creditsResponse = await getCredits(email);
          setCredits(creditsResponse || 0);
          console.log('Credits: ', creditsResponse);
          // if (creditsResponse.success) {
          //   setCredits(creditsResponse.credits || 0);
          //   console.log('Credits: ', credits.credits);
          // } else {
          //   console.error("Failed to fetch credits:", creditsResponse.message);
          //   setCredits(0);
          // }
        }
      }
    };

    checkAuth();
  }, []);

  const handleSignOut = async () => {
    const response = await logoutUser();
    if (response.success) {
      alert(response.message);
      setLoggedIn(false);
      navigate("/login");
    } else {
      alert(response.message);
    }
  };

  // Determine if the current path is '/home'
  const isHomeRoute = location.pathname === "/home";

  return (
    <div className="flex flex-col bg-gray-900 text-white">
      <nav className="flex items-center justify-between px-8 py-4 bg-gray-900 fixed w-full z-10">
        <div>
          <img
            src={`${process.env.PUBLIC_URL}/Desync_Logo_No_Background.png`}
            alt="Desync AI Logo"
            className="h-10 w-auto"
            style={{ filter: "invert(1)" }}
          />
        </div>
        <div className="md:hidden">
          <button
            onClick={() => setIsOpen(!isOpen)}
            className="text-white focus:outline-none"
          >
            <svg className="h-6 w-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
              {isOpen ? (
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M6 18L18 6M6 6l12 12"
                />
              ) : (
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M4 6h16M4 12h16M4 18h16"
                />
              )}
            </svg>
          </button>
        </div>
        <ul className="hidden md:flex space-x-6 items-center">
          {loggedIn ? (
            <>
              {!isHomeRoute && (
                <li>
                  <NavLink to="/home" className="hover:text-blue-400">
                    Home
                  </NavLink>
                </li>
              )}
              <li>
                <NavLink to="/add-credits" className="hover:text-blue-400">
                  {credits !== null && `Number of credits: ${credits}`}
                </NavLink>
              </li>
              <li>
                <button
                  onClick={handleSignOut}
                  className="border border-blue-500 px-4 py-2 rounded hover:bg-blue-500 hover:text-white"
                >
                  Sign Out
                </button>
              </li>
            </>
          ) : (
            <>
              <li>
                <NavLink to="/login" className="hover:text-blue-400">
                  Login
                </NavLink>
              </li>
              <li>
                <NavLink to="/register" className="hover:text-blue-400">
                  Register
                </NavLink>
              </li>
            </>
          )}
        </ul>
      </nav>
      {isOpen && (
        <div className="fixed top-16 left-0 w-full bg-gray-800 md:hidden z-20">
          <ul className="px-2 pt-2 pb-3 space-y-1 sm:px-3">
            {loggedIn ? (
              <>
                {!isHomeRoute && (
                  <li>
                    <NavLink
                      to="/home"
                      className="block px-3 py-2 rounded-md text-base font-medium text-white hover:bg-gray-700"
                      onClick={() => setIsOpen(false)} // Close menu on click
                    >
                      Home
                    </NavLink>
                  </li>
                )}
                <li>
                  <NavLink
                    to="/add-credits"
                    className="block px-3 py-2 rounded-md text-base font-medium text-white hover:bg-gray-700"
                    onClick={() => setIsOpen(false)} // Close menu on click
                  >
                    Add Credits {credits !== null && `(${credits})`}
                  </NavLink>
                </li>
                <li>
                  <button
                    onClick={() => {
                      handleSignOut();
                      setIsOpen(false);
                    }}
                    className="block px-3 py-2 rounded-md text-base font-medium border border-blue-500 text-white hover:bg-blue-500"
                  >
                    Sign Out
                  </button>
                </li>
              </>
            ) : (
              <>
                <li>
                  <NavLink
                    to="/login"
                    className="block px-3 py-2 rounded-md text-base font-medium text-white hover:bg-gray-700"
                    onClick={() => setIsOpen(false)} // Close menu on click
                  >
                    Login
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to="/register"
                    className="block px-3 py-2 rounded-md text-base font-medium text-white hover:bg-gray-700"
                    onClick={() => setIsOpen(false)} // Close menu on click
                  >
                    Register
                  </NavLink>
                </li>
              </>
            )}
          </ul>
        </div>
      )}
    </div>
  );
};

export default Navbar;
