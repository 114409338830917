import React, { useState, useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import { isAuthenticated } from '../Auth/AuthService'; // Import the AuthService function

const ProtectedRoute = ({ element: Component, ...rest }) => {
  const [redirectTo, setRedirectTo] = useState(null);
  const [loading, setLoading] = useState(true); // For handling async calls

  useEffect(() => {
    const verifyUser = async () => {
      const response = await isAuthenticated();

      if (response.success) {
        // User is authenticated, allow access
        setRedirectTo(null);
      } else {
        // User is not authenticated, redirect to login
        setRedirectTo('/login');
      }

      setLoading(false); // Finish loading after verification
    };

    verifyUser();
  }, []);

  // Show a loading indicator while authentication is being checked
  if (loading) {
    return <div>Loading...</div>;
  }

  // Handle redirection based on authentication status
  if (redirectTo) {
    return <Navigate to={redirectTo} replace />;
  }

  // If no redirection is needed, render the component
  return <Component {...rest} />;
};

export default ProtectedRoute;
