const API_BASE_URL = "https://dev-tool-backend-zj7w8.ondigitalocean.app/"; // Backend base URL

/**
 * Register a new user.
 * @param {Object} userData - The user data for registration.
 * @returns {Object} Response data containing user information or error.
 */
export const registerUser = async (userData) => {
  try {
    const response = await fetch(`${API_BASE_URL}/register`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(userData),
    });

    if (response.ok) {
      const data = await response.json();
      // Store sessionToken and ourUserId in localStorage
      localStorage.setItem("ourUserId", data.user.ourUserId); // Save ourUserId
      localStorage.setItem("email", data.user.email); // Save email
      return { success: true, data: data.user };
    } else {
      const errorData = await response.json();
      return { success: false, message: errorData.error.message };
    }
  } catch (error) {
    return { success: false, message: "An error occurred while registering." };
  }
};


/**
 * Log in a user.
 * @param {Object} credentials - The login credentials (email and password).
 * @returns {Object} Response data containing user information or error.
 */
export const loginUser = async (credentials) => {
  try {
    const response = await fetch(`${API_BASE_URL}/login`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(credentials),
    });

    if (response.ok) {
      const data = await response.json();
      // Store sessionToken and ourUserId in localStorage
      localStorage.setItem("sessionToken", data.sessionToken);
      localStorage.setItem("ourUserId", data.user.ourUserId); // Save ourUserId
      localStorage.setItem("email", data.user.email); // Save email
      return { success: true, data };
    } else {
      const errorData = await response.json();
      return { success: false, message: errorData.error.message };
    }
  } catch (error) {
    return { success: false, message: "An error occurred while logging in." };
  }
};



export const isAuthenticated = async () => {
    const sessionToken = localStorage.getItem("sessionToken");
    const email = localStorage.getItem("email");
    if (!sessionToken) {
      return { success: false, message: "No session token found." };
    }
  
    try {
      const response = await fetch(`${API_BASE_URL}/is-authenticated`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "X-Parse-Session-Token": sessionToken,
          "email": email,
        },
      });
  
      if (response.ok) {
        const data = await response.json();
        return { success: true, data };
      } else {
        const errorData = await response.json();
        return { success: false, message: errorData.message };
      }
    } catch (error) {
      console.error("Error checking authentication:", error);
      return { success: false, message: "An error occurred while checking authentication." };
    }
};

export const logoutUser = async () => {
    const sessionToken = localStorage.getItem("sessionToken");
  
    if (!sessionToken) {
      return { success: false, message: "No session token found." };
    }
    
    // Remove the session token from localStorage
    localStorage.removeItem("sessionToken");
    localStorage.removeItem("email");
    localStorage.removeItem("ourUserId");
    return { success: true, message: "Logged out successfully." };

  };
  

